<script lang="ts" setup>
const props = defineProps<{
  visible: boolean;
}>();
const visible = toRef(props, 'visible');
const emit = defineEmits<{
  (event: 'update:visible', value: boolean): void;
}>();

const { t } = useI18n();
const [modalVisible, onToggleVisible] = useExternalModalState(visible, (v) =>
  emit('update:visible', v),
);
</script>

<template>
  <BaseModal
    :visible="modalVisible"
    @update:visible="onToggleVisible"
    :aria-title="t('aria_title')"
    :aria-describedby="t('aria_description')"
    container-class="productSubscriptionInfoModal"
  >
    <h6 class="productSubscriptionInfoModal__title">{{ t('title') }}</h6>
    <i18n-t
      keypath="content"
      tag="p"
      class="productSubscriptionInfoModal__description"
    >
      <template #discount>{{ SUBSCRIPTION_DISCOUNT_VALUE }}</template>
      <template #link>
        <NuxtLink
          @click="onToggleVisible(false)"
          to="/profile"
          class="productSubscriptionInfoModal__link"
        >
          {{ t('link') }}
        </NuxtLink>
      </template>
      <template #step1>
        <b>Шаг 1</b>
      </template>
      <template #step2>
        <b>Шаг 2</b>
      </template>
      <template #step3>
        <b>Шаг 3</b>
      </template>
      <template #step4>
        <b>Шаг 4</b>
      </template>
    </i18n-t>

    <BaseButton
      @click="onToggleVisible(false)"
      outlined
      dark
      class="productSubscriptionInfoModal__action"
    >
      {{ t('accept') }}
    </BaseButton>
  </BaseModal>
</template>

<i18n>
ru:
  aria_title: Как работает подписка на продукты
  aria_description: Подробная информация о работе подписки на продукты
  title: Как работает подписка
  content: >
    {step1}

    Выберите средство, к которому вы хотите получать рефил


    {step2}

    На странице карточки товара переместите бегунок в блоке «Подписка на рефил со скидкой 30%»


    {step3}

    Оформите заказ, и ваша подписка активируется. За рефил вы пока не платите


    {step4}

    Доставка рефила — каждые 3 месяца. В указанный на сайте день доставки мы с вами свяжемся и попросим оплатить рефил со скидкой 30%


    Информация о ваших подписках доступна в {link}.
  link: личном кабинете
  accept: Ок, понятно

en:
  aria_title: How subscription works
  aria_description: Detailed information about how subscription works
  title: How subscription works
  content: >
    Step 1

    Select the tool you wish to receive a refill for


    Step 2

    On the product card page, move the slider in the “Subscribe to Refill at 30% discount” block


    Step 3

    Place your order and your subscription will be activated. You will not pay for the refil yet


    Step 4

    Refil delivery is every 3 months. On the delivery date specified on the website, we will contact you and ask you to pay for the refil at a 30% discount.


    Information about your subscriptions is available in {link}.
  link: personal account
  accept: Ok, understood
</i18n>

<style lang="scss">
.productSubscriptionInfoModal {
  color: #121212;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  max-width: 649px;
  padding: 37px 40px 45px;

  @include mq('sm') {
    padding: 51px 16px 41px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 15px;

    @include mq('sm') {
      text-align: center;
      margin-bottom: 32px;
    }
  }

  &__description {
    margin: 0;
    white-space: pre-wrap;

    @include mq('sm') {
      margin-bottom: auto;
    }
  }

  &__link {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  &__action {
    margin: 63px auto 0;
    width: 344px;
    max-width: 100%;

    @include mq('sm') {
      width: 100%;
    }
  }
}
</style>
